.HotelInfo{
    text-align: center;
}

.ViewBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.viewHotel{
    background-color: black;
    color: white;
    padding: 10px 40px;
}

.viewHotel:hover{
    background-color: rgba(11, 10, 10, 0.923);
    color: rgb(255, 255, 255);
}

