
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.list-items {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 3px 4px;
    margin: 3px 16px;
    border-bottom: 2px solid transparent;
    letter-spacing: 2px;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    font-style: italic;
    font-weight: 500;
    transition: 0.2s;
    position: relative;
}


.item:hover {
    color: #231f20;
    border-bottom: 2px solid rgba(35, 31, 32, 70%);
}

.active {
    position: absolute;
    border-bottom: 2px solid rgba(35, 31, 32, 70%);
    width: 100%;
    height: 3px;
    margin-top: 22px;
}

.dropdown {
    z-index: 100;
    position: fixed;
    top: 0;
    right: auto;
    font-size: 24px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    width: 100%;
    display: none;
}

.dropdown-content i {
    padding: 15px;
    z-index: 1000000;
    position:absolute;
    font-size: 25px;
    top: 3px;
    left: 5px;

}

.dropdown-content {
    padding: 15px;
    display: flex;
    color: #231f20;
}

.dropdown-content h1 {

    color: #231f20;
    margin: auto;
    padding-right: 20px;
    letter-spacing: 2px;
    font-style: italic;
}

.list-itemsdropdown {
    display: none;
}

@media (max-width: 768px) {
    .dropdown {
        display: block;
    }
    .navbar {
        position: relative;
        margin-bottom: 0;

    }
    .nav-menu .list-items {
        display: flex;
        flex-direction: column;
    }
    .nav-menu .list-items li{
        margin: 10px;

    }
    .list-items{
        display: none;
    }
    .nav-menu {
        transition: 0.5s ease-in-out;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgb(248, 248, 248);
        padding-top: 200px;
    }
    .nav-menu.open{
        right: 0;
        transition: 0.2s ease-in-out;
    }
}

@media (max-width: 450px) {
    .menu-text{
        font-size: 25px;
    }
    .list-itemsdropdown {
        width: 100%;
    }
}
@media (max-width: 370px) {
    .menu-text{
        font-size: 20px;
    }
    .list-itemsdropdown {
        width: 100%;
    }
}
