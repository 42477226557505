.footer{
    margin-top: 40px;
    position: relative;
}

.footer-logo{
    letter-spacing: 8px;
    font-size: 46px;
    padding: 0 18px 16px;
    font-weight: 400;
    text-align: center;
    width: 250px;
    margin: auto;
    border-bottom: 1px solid black;
}
.footer-date{
    padding-top: 20px;
    letter-spacing: 4px;
    font-size: 20px;
    text-align: center;

}

.footerImg{
    position: absolute;
    right: 0;
    top: 100%;
}
.footerImg img{
    object-fit: cover;
    width: 100%;
    height: 300px;

}