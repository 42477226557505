.Header{
    background-image: url('../Images/Leaf2Img.webp');
    width: 100%;
    height: 300px;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #231f20;
    margin-bottom: 140px;
}

.header-context{
    margin-top: 150px;
    text-align: center;
}

.header-context img{
    width: 100%;
    margin-top: 100px;
    max-width: 800px;
}

.header-context h1{
    letter-spacing: 8px;
    font-size: 46px;
    font-weight: 400;

}
.header-context h6{
    font-family: 'EB Garamond', serif;
    font-size: 18px;
    margin: 5px 0;
    letter-spacing: 3px;
    line-height: 1;
    font-weight: normal;
}
.header-context span{
    padding-right: 6px;
    font-size: 70px;

}
@media (max-width: 1200px) {
    .header-context{
        margin-top: 240px;
        margin-bottom: 100px;
    }
}

@media (max-width: 768px) {
    .Header{
        margin-top: 50px;
        background-image: url('../Images/Leaf2Img.webp');
        margin-bottom: 50px;
    }
    .header-context{
        margin-top: 130px;
    }

}


@media (max-width:640px) {
    .header-context h1{
        font-size: 36px;
    }
    .Header{
        margin-top: 40px;
        margin-bottom: 0px;
    }
    .header-context{
        margin-top: 30px;
    }

}

@media (max-width: 500px) {
    
    .header-context{
        margin-top: 60px;
    }
   

}

@media (max-width:400px) {
    .header-context h1{
        font-size: 26px;
    }
    .header-context{
        margin-top: 10px;
    }
    .Header{
        margin-bottom:0px;
    }
}



